<template>
<v-container fluid class="pl-0 pr-0">
    <v-row justify="center">
        <v-col cols="12" sm="9">
            <v-card outlined>
                <v-card-title :class="`mt-5 ${getTitleSize()}`">
                    <v-divider class="ma-5" />
                        Latest Posts
                    <v-divider class="ma-5" />
                </v-card-title>

                <zoom-center-transition :duration="500">
                    <v-card-text v-show="posts.length > 0">
                        <v-row justify="center" :class="getBreakpointName() === 'xs' ? '' : 'mx-4'">
                            <v-col v-for="post in posts" :key="post.slug" cols="12" sm="6" lg="4" xl="3">
                                <HoverCard>
                                    <template v-slot:content>
                                        <PostCard :post="post" />
                                    </template>
                                </HoverCard>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </zoom-center-transition>
                <v-card-text v-show="posts.length === 0">
                    <v-row justify="center">
                        <v-col cols="11">
                            <v-card outlined>
                                <v-card-text class="text-center title">No posts found.</v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions class="pt-0">
                    <v-container>
                        <v-row align="center" no-gutters>
                            <v-col cols="12" class="text-right">
                                <v-btn tile outlined :to="{ name: 'travel'}">
                                    See More
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
    <v-row justify="center">
        <v-col cols="12" sm="9">
            <PostList>
                <template v-slot:map>
                    <WorldMap />
                </template>
            </PostList>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import { ZoomCenterTransition } from "vue2-transitions";
import { mapGetters } from "vuex";

export default {
    name: "home",

    metaInfo() {
        return this.meta(this.$options.name, window.location.href);
    },

    components: {
        HoverCard: () => import("@/components/HoverCard").then(m => m.default),
        PostCard: () => import("@/components/PostCard").then(m => m.default),
        WorldMap: () => import("@/components/WorldMap").then(m => m.default),
        PostList: () => import("@/components/PostList").then(m => m.default),
        ZoomCenterTransition
    },

    data() {
        return {
            cardsToDisplay: [],
            cardAmount: 0,
            posts: []
        }
    },

    async mounted() {
        this.posts = this.latestPosts;
    },

    computed: {
        ...mapGetters(["latestPosts", "meta"])
    },

    methods: {},

    watch: {
        latestPosts(val) {
            this.posts = val;
        }
    }
};
</script>

<style lang="scss">
</style>
